import React, { useContext, useEffect, useState } from "react";
import { Store } from "../Store";
import { addToOrder, removeFromOrder } from "../actions";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  createOrder,
  setPaymentType,
  generateQrCode,
  clearOrder,
  getCheckoutUrl,
  getUserData,
  updateOrder,
} from "../actions";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useStyles } from "../styles";
import Chip from "@mui/material/Chip";
import Logo from "../components/Logo";
// import Slider from "react-slick";
import config, { getParameterByName, merchantCode } from "../Configs";
import { FETCH_ORDER_SUCCESS } from "../constants";
export default function ReviewScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const {
    orderItems,
    itemsCount,
    totalPrice,
    taxPrice,
    orderType,
    tableNumber,
    orderId,
  } = state.order;
  console.log(state.order);
  console.log(orderItems);
  const selectedCurrency = state.selectedCurrency;
  console.log(selectedCurrency);
  let { setting } = state.userSetting;
  console.log(setting);
  const { order } = state;
  const baseURL = config.baseURL;
  const [existingData, setExistingData] = useState();
  let isPaymentAllowed = setting ? setting.isPaymentAllowed : "";
  console.log(isPaymentAllowed);
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState({});
  const closeHandler = () => {
    setIsOpen(false);
  };
  const productClickHandler = (p) => {
    console.log(p);
    console.log(orderItems)
    let existingOrder = orderItems.filter((o) => o._id == p.id);
    setProduct(p);
    if (existingOrder.length) {
      setQuantity(existingOrder[0].quantity);
    } else {
      setQuantity(1);
    }
    setIsOpen(true);
  };
  let table = getParameterByName("tableNumber");
  console.log(table);


  const addToOrderHandler = () => {
    addToOrder(dispatch, { ...product, quantity });
    setIsOpen(false);
  };
  const cancelOrRemoveFromOrder = () => {
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };
  useEffect(() => {
    const tabId = localStorage.getItem("tableId");
    console.log(orderItems.length);
    if (tabId && orderItems.length === 0) {
      axios.get(`${baseURL}/api/orders/number/${tabId}`).then((res) => {
        console.log(res.data);
        setExistingData(res.data);
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: res.data[0],
        });
      });
    }
  }, []);
  const procedToCheckoutHandler = () => {
    let ord = { ...state.order };

    ord.orderItems = ord.orderItems.map((orderItem) => ({
      ...orderItem,
      status: orderItem.status ? orderItem.status : "Preparing",
      sub_pro:
        typeof orderItem.sub_pro !== "string"
          ? JSON.stringify(orderItem.sub_pro)
          : orderItem.sub_pro,
    }));
    console.log(ord);
    if (state.order && state.order.orderId) {
      console.log("newOrder");
      updateOrder(dispatch, ord, state.order.orderId);
    } else {
      createOrder(dispatch, ord);
      localStorage.removeItem("newOrder");
    }
    if (isPaymentAllowed) {
      props.history.push(
        "/select-payment?" + window.location.href.split("?")[1]
      );
    } else {
      props.history.push("/addmore?" + window.location.href.split("?")[1]);
    }
  };

  if (setting) {
    setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);
  }

  return (
    <Box className={[styles.root]} style={{ overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "60px",
          padding: "10px",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "190px",
          }}
        >
          <span
            onClick={() => {
              props.history.push(
                `/order?` + window.location.href.split("?")[1]
              );
            }}
            className="back_arrow"
          >
            <img
              src="images/back.png"
              alt="BigCo Inc. logo"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
          <h2>Table # {table}</h2>
        </span>

        <h1 align="start" id="rtxt">
          View Order
        </h1>
        <div></div>
      </div>
      <Box className={[styles.main, styles.center]} id="reviewmain">
        <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          open={isOpen}
          fullWidth={true}
          // maxWidth="xs"
          maxWidth={state.widthScreen ? "sm" : "xs"}
          id="diaadd"
        >
          <DialogTitle className={styles.center}>
            Add {product.name}
          </DialogTitle>
          <Box className={[styles.row, styles.center]}>
            <Button
              className={styles.minus}
              id="minus_btn"
              variant="contained"
              color="primary"
              disabled={quantity === 1}
              onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}
            >
              <RemoveIcon />
            </Button>
            <TextField
              id="order_count"
              inputProps={{ className: styles.largeInput }}
              className={styles.largeNumber}
              type="number"
              min={1}
              variant="filled"
              value={quantity}
            />
            <Button
              className={styles.add}
              id="plus_btn"
              variant="contained"
              color="primary"
              onClick={(e) => setQuantity(quantity + 1)}
            >
              <AddIcon />
            </Button>
          </Box>
          <Box className={[styles.row, styles.around]}>
            <Button
              onClick={cancelOrRemoveFromOrder}
              variant="outlined"
              color="primary"
              size="large"
              className={[styles.AddlargeButton]}
              style={{ color: "black" }}
            >
              {orderItems.find((x) => x.name === product.name)
                ? "Remove"
                : "Cancel"}
            </Button>

            <Button
              onClick={addToOrderHandler}
              variant="contained"
              color="primary"
              size="large"
              className={styles.rightlargeButton}
            >
              ADD
            </Button>
          </Box>
        </Dialog>

        <Box></Box>
        <Box
          style={{
            overflowY: "auto",
            padding: "5px",
            width: "100%",
            height: "100%",
          }}
        >
          <table
            align="center"
            style={{ width: "95%" }}
            cellPadding="3px"
            cellSpacing="2px"
          >
            <thead>
              <tr
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  lineHeight: "2",
                  fontFamily: "Arial",
                  boxShadow: "0px 2px 0px black",
                  paddingBottom: "20px",
                }}
                id="tablehead"
              >
                <th id="rev-img"></th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ height: "100%", overflow: "scroll" }}>
              {orderItems.length
                ? orderItems.map((orderItem) => {
                    const subProArray =
                      typeof orderItem.sub_pro === "string"
                        ? JSON.parse(orderItem.sub_pro)
                        : orderItem.sub_pro;
                    console.log(subProArray);
                    const subProNames =
                      subProArray && subProArray.addons
                        ? subProArray.addons.map((subPro) => subPro.name)
                        : [];
                    const subProInstruction =
                      subProArray && subProArray.cookInstructions
                        ? subProArray.cookInstructions.nameInstruction
                        : "";
                    const subProvarieties =
                      subProArray && subProArray.variety
                        ? subProArray.variety.nameVariety
                        : "";
                    const addonTotalPrice =
                      subProArray && subProArray.addons
                        ? subProArray.addons.reduce((acc, val) => {
                            if (val.price) {
                              return acc + parseFloat(val.price);
                            }
                            return acc;
                          }, 0)
                        : 0;
                    console.log(orderItem.sub_pro);
                    console.log(state.order);
                    return (
                      <>
                        <tr
                          style={{
                            textAlign: "left",
                            fontSize: "15px",
                            fontFamily: "Arial",
                            boxShadow: "0px 2px 0px lightgray",
                          }}
                          id="tdtab"
                        >
                          <td id="rev-img">
                            <img
                              src={
                                orderItem.image
                                  ? `${baseURL}/${orderItem.image}`
                                  : `${baseURL}/app-uploads/customers/inventories/pro_1708595131591food-icon-free-icons-easy-to-download-and-use-775485.png`
                              }
                              style={{
                                height: "80px",
                                width: "80px",
                                borderRadius: "22px",
                              }}
                            />
                          </td>
                          <td id="txttab">
                            <b>{orderItem.name} </b>
                            <br />
                            {subProNames.length > 0 ? (
                              <Chip
                                label={subProNames.join(", ").toUpperCase()}
                                color="default"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              />
                            ) : (
                              subProNames
                            )}{" "}
                            {subProInstruction ? (
                              <Chip
                                label={subProInstruction.toUpperCase()}
                                color="default"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "8px",
                                  fontWeight: "bold",
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {subProvarieties ? (
                              <Chip
                                label={subProvarieties.toUpperCase()}
                                color="default"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <h4>{orderItem.quantity}</h4>
                          </td>
                          <td>
                            <Chip
                              label={
                                orderItem.status === "delivered"
                                  ? "Served".toUpperCase()
                                  : orderItem.status
                                  ? orderItem.status.toUpperCase()
                                  : "New".toUpperCase()
                              }
                              color={
                                orderItem.status === "ready"
                                  ? "primary"
                                  : orderItem.status === "Preparing"
                                  ? "warning"
                                  : orderItem.status === "delivered"
                                  ? "success"
                                  : "info"
                              }
                              style={{
                                marginLeft: "10px",
                                fontSize: "10px",
                                fontWeight: "bold",
                              }}
                            />
                          </td>

                          <td>
                            <h4>
                              {selectedCurrency}{" "}
                              {addonTotalPrice
                                ? orderItem.price + addonTotalPrice
                                : orderItem.price}
                            </h4>
                          </td>
                          <td>
                            {orderItem.status ? (
                              ""
                            ) : (
                              <>
                                <EditIcon
                                  className="rev_edit"
                                  onClick={() => productClickHandler(orderItem)}
                                />
                                <RemoveCircleOutlineIcon
                                  className="rev_remove"
                                  onClick={() =>
                                    removeFromOrder(dispatch, orderItem)
                                  }
                                />
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </Box>
      </Box>

      <Box>
        <Box>
          <Box
            className={[styles.row, styles.end]}
            style={{
              paddingRight: "40px",
              position: "fixed",
              bottom: 0,
              width: "100%",
              backgroundColor: "#ffffff",
            }}
            id="placeo-box"
          >
            {/* <button
              onClick={() => {
                props.history.push(`/order?` + window.location.href.split('?')[1]);
              }}
              style={{background:"grey",margin:"0ox 44px"}}
              className="back"
              id='review_clbtn'
            >
              Back
            </button> */}

            <button
              onClick={procedToCheckoutHandler}
              style={{
                background: "rgb(255, 188, 0)",
                color: "#000",
                margin: "0ox 44px",
                fontWeight: "bold",
              }}
              disabled={orderItems.length === 0}
              className="place_order"
            >
              {state.order && state.order.orderId
                ? "Update Order"
                : "Order Now"}
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
