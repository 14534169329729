import React,  { useContext, useEffect, useState } from 'react';
import { useStyles } from '../styles';
import { Store } from "../Store";
import axios from "axios";
import config, { getParameterByName, merchantCode } from "../Configs";
import {
  clearOrder,
  removeFromOrder,
  getUserData
} from "../actions";
import {
  Button,
 
} from "@material-ui/core";
import BackButton from './Back_Button';
import NotificationsIcon from "@mui/icons-material/Notifications";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function Header(props) {
   const styles = useStyles();
   const { state, dispatch } = useContext(Store);
   const { table }=state;
   const baseURL = props.baseURL;
  
  const handleCallService = () => {
    const tblId = table.details.id;
      axios
        .put(`${baseURL}/api/tables/${tblId}/service-request`, {
          isServiceCall: !table.details.isServiceCall,
          merchantCode: merchantCode,
        })
        .then((res) => {
          console.log(res.data);
          props.getTableDetails();
        })
        .catch((error) => {
          console.error("Error starting service call:", error);
        });
    }

  return (
    <div
        style={{
         width:"100%",
         height:"50px",
         display:'flex',
         flexDirection:'row',
         justifyContent: 'space-between'
        }}
      >
      <Button
          variant="contained"
          color="success"
          id="waiterBtn"
          startIcon={<NotificationsIcon />}
          onClick={handleCallService}
          style={{
                height: '40px',
                marginTop:'5px',
                backgroundColor:table.details.isServiceCall?"#e40808":"#000"
          }}
        >
          {table.details.isServiceCall?"Calling...":"Staff"}
        </Button>
        <h2 style={{marginRight:"10px"}}>{'Table #'+table.details.number}</h2>

        <Button
          variant="contained"
          color="success"
          id="refresh-all"
          startIcon={<RefreshIcon />}
          onClick={props.refreshAll}
           style={{
                height: '40px',
                margin:'10px',
                background:'#fff',
                color:"#000"
              }}
        >
          
        </Button>
      </div>

  );
}
