import React,  { useContext, useEffect, useState } from 'react';
import { useStyles } from '../styles';

export default function BackButton(props) {
 const styles = useStyles();
  
  return (
    <div  onClick={() =>{props.handleClick()}}>
       <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "auto",
          }}
        >
          <span className="back_arrow">
            <img
              src="images/back.png"
              alt="BigCo Inc. logo"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
        </span>
      </div>

  );
}
