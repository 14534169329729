import React, { useContext, useEffect, useState } from 'react';
import { NumericKeyboard } from 'react-numeric-keyboard';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Store } from '../Store';
import { Alert } from '@material-ui/lab';
import { createOrder, setPaymentType, generateQrCode, clearOrder, getCheckoutUrl,getUserData } from '../actions';
import QRCode from "react-qr-code";
import axios from 'axios';
import HomeScreen from './SelectPaymentScreen';



export default function CompleteOrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { order, selectedCurrency } = state;
  const { loading, error, newOrder } = state.orderCreate;
  console.log(loading);
  const [display, setDisplay] = useState(false);
  const [fontColor, setFontColor] = useState();
  const [displayOrder, setDisplayOrder] = useState(false);
  const [invData,setInvData]=useState();
  const [detailview,setDetailView] = useState(false);
  let {setting} = state.userSetting;
    console.log(setting);
let {userInfo}=state.userData;
console.log(userInfo);
  const [phnumber, setPhnumber] = useState("");
  console.log(phnumber)
  const getParameterByName = (e, t = window.location.href) => {
    e = e.replace(/[\[\]]/g, "\\$&"); var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
    return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null
  }
  let sokURL = window.location.href.indexOf('localhost') > 0 ?'https://tto.menulive.in':window.location.origin;
  // const isPaymentAllowed = getParameterByName("isPaymentAllowed");
  const baseURL = sokURL.replace("tto","api");
  const bgColor = getParameterByName("bgColor");
  const userCurrency =userInfo? userInfo.currency:"";
  const isScan = getParameterByName("isScan");
  const payURL="https://pay.digitallive24.com";


  const email =userInfo? userInfo.email:"";
  const upiId = userInfo ? userInfo.paymentProviderCode : "";
  const upiName = userInfo ? userInfo.firstName : "";
  const gst =userInfo? userInfo.taxCodeNo:"";

   let isPaymentAllowed = setting?setting.isPaymentAllowed:"";
  console.log(isPaymentAllowed);

let paytimer = null;
 

    

  console.log(isScan);
  console.log(selectedCurrency);

  console.log(newOrder);
  // useEffect(()=>getUserData(dispatch),[]);

  useEffect(() => {
      console.log("gdjkhlk");
      // getInvNumber();
    
    if (order.orderItems.length > 0) {
      let items = order.orderItems.map((o) => {
        o["status"] = "inProgress";
        return o;
      });
      console.log(items);
      order.orderItems = items;
      // order.paymentType = order.paymentType ? order.paymentType : "At Counter";
      order.paymentType = isPaymentAllowed ? order.paymentType : "At Counter";
      console.log(order)
      // createOrder(dispatch, order);

    }

  }, [order]);



   if(newOrder){
          console.log("dfglliurghrg");
        sessionStorage.setItem("newOrder",JSON.stringify(newOrder));
      }



  // const getInvNumber=()=>{
  //    axios.get(`${payURL}/api/invoice/latest`).then((res) => setInvData(res.data));
  // }


    console.log(invData);
  const handleBack = () => {
    setDisplayOrder(false);
  }
  const handleForm = () => {
    setDisplay(!display);
  }
  const backSpaceImage = () => {
    return (
      <img src='/images/backspace-1.png' alt="backSpace" height="40vh" width="40vw" />
    )
  }

  let appUrl = window.location.href.replace("complete", "");
  let updateUrl = appUrl.replace(/&/g, "~");
  console.log(updateUrl);

   let orderDetails=state.order;
   console.log(orderDetails);

  let summaryPath1 =orderDetails ? `${sokURL}/app.html?serve_url=${baseURL}&orderId=${orderDetails ?orderDetails.id : ""}&merchantCode=${userInfo?userInfo.merchantCode:""}&currency=${userCurrency}&restaurant=${upiName}&cgst=${gst}&invoice=${invData&&invData.replace("dl","DL_")}&sok_url=${updateUrl}` : "";
  // console.log(`${sokURL}/app.html?serve_url=${baseURL}&orderId=${newOrder ? newOrder._id : ""}&currency=${userCurrency}&sok_url=${updateUrl}`);
  
console.log(encodeURI(summaryPath1));

    let paymentUrl = orderDetails
    ? `upi://pay?pn=${upiName}&pa=${upiId}&am=${orderDetails.totalPrice}&tn=${orderDetails.number}`
    : "";
  
  const handleSubmit=()=>{
    let msgtext=`${encodeURI(summaryPath1)}`;
    axios.post(baseURL.replace("apps","cms")+"/api/send-sms-msg",{toMobile: `+91${phnumber}`, msgConent: msgtext})
    .then((res)=>{
     setPhnumber("");
    })
   }
  
  const handlePayment=()=>{
    //  axios.post(`${baseURL}/fbs/ratings`,
    //       {
    //         queryId:setting.userId,
    //         rating:price,
    //         locId:token,
    //         customer_img_path:""
    //       }
    //   ).then(res=>console.log(res.data));

      if (paytimer){ clearInterval(paytimer)};
      setDetailView(!detailview);
      
    }


 const orderStauts =()=>{
      let id =orderDetails._id
        axios.get(`${baseURL}/api/orders/${id}`)
        .then(res=>{
          if(res.data.isPaid){
             if (paytimer){ clearInterval(paytimer)}

            setDetailView(true);
          }
        })
    }
 
      function startTimer() {
    var duration = 60 * 10;
    var timer = duration, minutes, seconds;
    if (paytimer) clearInterval(paytimer);
    paytimer = setInterval(function () {


      let display = document.getElementById('time');
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
     

      if (--timer < 0) {
        timer = duration;
        if (paytimer) clearInterval(paytimer);
        console.log("Qr Expired");
      }

      if (seconds % 10 === 0) {
        console.log("hello");
        orderStauts();
      }
    }, 1000);

  }

// if(orderDetails&&!isPaymentAllowed){
//   startTimer();
// }


if(setting){
       setTimeout(() => {
        let textcolor = document.getElementById("title1");
        textcolor.style.color = setting.color_primary;
      }, 200);
   }


  return (
    <Box className={[styles.root1, styles.navy]} style={{ backgroundColor: state.selectedBgColor }}>
      <Box className={[styles.main1, styles.center]} id="pay">
        <Box style={{ marginTop: "30px", padding: "10px" }}>
          {/* <img src={state.selectedLogo} height='80px' width='100px' /> */}
          { !orderDetails && loading   ? (
            <CircularProgress></CircularProgress>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <>
                <Typography
                  gutterBottom
                  variant="h3"
                  component="h3"
                  id="title1"
                  style={{fontSize:"25px" }}
                >
                 {detailview ||isPaymentAllowed?"":"Process your Payment"}
                </Typography>
              {/* <div className={styles.order}> */}

              {/* {detailview ||isPaymentAllowed?  <Typography
                          gutterBottom
                          className={styles.title3}
                          // style={{padding:"50px 100px 0px 0px"}}
                          variant="h3"
                          component="h3"
                        >
                          Token #<span className={styles.orderNum}>{orderDetails.number}</span>
                        </Typography>:""} */}


               


              
              <h5 style={{ color: "black", fontWeight: "bold" }}>{detailview ||isPaymentAllowed?"":"Scan to Pay"}</h5>
           {  !displayOrder && false ? <div style={{  marginTop: "25px" }}>
               {!display ? <button className='btn btn-dark' onClick={handleForm}>Enter Your Mobile No.</button>:
               
               <Box style={{ display: 'flex' }} >
               <input type='tel' value={phnumber} name="phoneNumber" className='form-control p-3' />

               {phnumber?<Button variant="contained"
                 color="secondary"
 
                 onClick={handleSubmit}                  
                 >submit
               </Button>:
               <Button variant="outlined" color="primary"
                
                 onClick={()=>setDisplay(false)}                  
                 >cancel
               </Button>}
             </Box>
               
               }<br/><br/>
                <span style={{marginTop:"20px"}}>( or )</span>
              <div style={{marginTop:"20px"}}>
               <button onClick={()=>setDisplayOrder(true)} className='btn btn-dark'>Skip I Will Remember</button>

              </div>
              </div>:"" }
             
              {/* QRCODE */}
             { orderDetails && !isPaymentAllowed && !detailview ? <div>
                            <div className='payqr_cont'>
                              <QRCode
                                size={456}
                                style={{ height: "250px", maxWidth: "250px", width: "100%" }}
                                value={paymentUrl}
                                viewBox={`0 0 456 456`}
                                />
                                </div>
                               <div>
                                <div style={{ marginTop: "20px" }}>
                                  <a href={paymentUrl} target='_blank' style={{ fontSize: "18px", color: "blue", fontWeight: "bold" }}>Click to Pay</a>
                                </div>
                              </div> 

                          <div>
                          <button className="pay-btn" onClick={()=>handlePayment()}>Done</button>
                          </div>

                        </div>:""}

                {detailview ||isPaymentAllowed ? <div>
                  <h3 align='center'>Payment Successful</h3>
                  <div className='payqr_cont' style={{width:"210px",height:"60px"}} >
                    <h4 >Total Bill :
                     {selectedCurrency} {orderDetails.totalPrice}</h4>
                    </div>
              </div>:"" }

              { isScan === "true" &&( detailview ||isPaymentAllowed) ? <div>
                <h6 style={{ marginTop: "15px" }}> (Bill)</h6>
                <div style={{ marginTop: "20px" }}>
                  <a href={encodeURI(summaryPath1)} target='_blank' style={{ fontSize: "18px", color: "blue", fontWeight: "bold" }}>Click Here</a>
                </div>
              </div> : " "}

              {isScan === "false" && detailview ||isPaymentAllowed ? <div>
                    <QRCode
                      size={456}
                      style={{ height: "150px", maxWidth: "150px", width: "150px", marginTop: "20px" }}
                      value={encodeURI(summaryPath1)}
                      viewBox={`0 0 456 456`}
                      />
                    {false&&<h6 onClick={()=>setDisplayOrder(false)}>go back</h6>}
                    <h6 style={{fontWeight:"20px",padding:"12px",fontSize:"20px"}}>Scan</h6>
                    
              </div>:"" }
             


            </>
          )}

            <Box>

              <NumericKeyboard
                isOpen={display}
                backSpaceIcon={backSpaceImage()}
                onChange={({ value, name }) => setPhnumber(value)} />

            </Box>
        </Box>
      </Box>


      {detailview ?
        <Box style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
          <button className='comp_btns'>Leave Review </button>
          <button
          className='end_btn'
            onClick={() => {
              if (paytimer) clearInterval(paytimer);
             sessionStorage.removeItem("newOrder");
              clearOrder(dispatch);
              props.history.push('/?' + window.location.href.split('?')[1]);
            }
            }
          >
            End Session
          </button>
        </Box>
  :""}


     
    </Box>
  );

}
