import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { StoreProvider } from './Store';
import { IntlProvider } from 'react-intl'
import initLocalisation from './assets/util/localisation/initLocalisation';
const container = document.getElementById('root');
const localisation = initLocalisation()



render(
  <IntlProvider {...localisation}>
  <StoreProvider>
    <App></App>
  </StoreProvider>,
  </IntlProvider>,
  container
);
