export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

export const ORDER_QUEUE_LIST_REQUEST = 'ORDER_QUEUE_LIST_REQUEST';
export const ORDER_QUEUE_LIST_SUCCESS = 'ORDER_QUEUE_LIST_SUCCESS';
export const ORDER_QUEUE_LIST_FAIL = 'ORDER_QUEUE_LIST_FAIL';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const ORDER_ADD_ITEM = 'ORDER_ADD_ITEM';
export const ORDER_REMOVE_ITEM = 'ORDER_REMOVE_ITEM';
export const ORDER_CLEAR = 'ORDER_CLEAR';
export const TABLE_NUMBER = 'TABLE_NUMBER';
export const ORDER_SET_PAYMENT_TYPE = 'ORDER_SET_PAYMENT_TYPE';

export const SCREEN_SET_WIDTH = 'SCREEN_SET_WIDTH';

export const GENERATE_QRCODE_REQUEST='GENERATE_QRCODE_REQUEST';
export const GENERATE_QRCODE_SUCCESS='GENERATE_QRCODE_SUCCESS';
export const GENERATE_QRCODE_FAIL='GENERATE_QRCODE_FAIL';

export const FETCH_QRCODE_REQUEST='FETCH_QRCODE_REQUEST';
export const FETCH_QRCODE_SUCCESS='FETCH_QRCODE_SUCCESS';
export const FETCH_QRCODE_FAIL='FETCH_QRCODE_FAIL';

export const FETCH_USERDATA_REQUEST='FETCH_USERDATA_REQUEST';
export const FETCH_USERDATA_SUCCESS='FETCH_USERDATA_SUCCESS';
export const FETCH_USERDATA_FAIL='FETCH_USERDATA_FAIL';

export const FETCH_USERSETTING_REQUEST='FETCH_USERSETTING_REQUEST';
export const FETCH_USERSETTING_SUCCESS='FETCH_USERSETTING_SUCCESS';
export const FETCH_USERSETTING_FAIL='FETCH_USERSETTING_FAIL';

export const UPDATE_ORDER='UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS='UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL='UPDATE_ORDER_FAIL';

export const FETCH_ORDER='FETCH_ORDER';
export const FETCH_ORDER_SUCCESS='FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAIL='FETCH_ORDER_FAIL';

export const TABLE_REQUEST='TABLE_REQUEST';
export const TABLE_SUCCESS='TABLE_SUCCESS';