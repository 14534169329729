import React, {useState }from 'react';
import { useStyles } from '../styles';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import BackButton from './Back_Button';
import {Box,Button} from "@material-ui/core";
import CurrencySymbol from './CurrencySymbol';
import { Store } from "../Store";
import axios from "axios";
import  { merchantCode } from "../Configs";

export default function CartReview(props) {
  const styles = useStyles();
  let t = props.t;
  const baseURL = props.baseURL;
  let oItems = props.orderItems.reverse();
  let isAnyNewItemAdded= false;
  let isAllItemsServed = oItems.filter(oi=> !oi.status||(oi.status.toUpperCase() != 'DELIVERED')).length?false:true;
 
  
  const requestBill = () => {
      axios
        .put(`${baseURL}/api/tables/${props.table.id}/service-request`, {
          isServiceCall: true,
          merchantCode: merchantCode,
        })
        .then((res) => {
          console.log(res.data);
          let msg = t({ id: "bill_msg" })
          props.notify(msg);
        })
        .catch((error) => {
          console.error("Error starting service call:", error);
        });
    }
 // props.history.push(
                //     `/complete?` + window.location.href.split("?")[1]
                //   );

  return (
    <Box style={{minHeight:"500px",padding:"10px",height:'auto'}}>
    <div className="cart_header" >
    <BackButton handleClick={()=>props.setIsCartOpen(false)}/>
        <span >{t({ id: "my_order" })}</span>
        {isAllItemsServed && <Button
              onClick={()=>(
               requestBill(), props.setIsCartOpen(false), props.getTableDetails())
               }
              variant="contained"
              className={styles.mdButton}
            >
             {t({ id: "request_bill" })}
            </Button>}
        </div>
          <div
            className="cart_items"
            style={{ maxHeight: "calc(100% - 110px)",width:"100%",padding:"10px", overflowY: "auto" }}
          >
            <table cellPadding="2px" cellSpacing="5px" id="tab1" style={{borderCollapse: 'collapse'}}>
              <thead>
                <tr  style={{
                            borderBottom: "2px solid #FFBC00",
                            fontSize: "15px",
                          }}>
                  <th  colSpan="2" style={{textAlign:'left'}}>{t({ id: "items" })}</th>
                  <th >{t({ id: "quantity" })}</th>
                 <th ></th>
                 
                </tr>
              </thead>
              <tbody>
                {oItems.length
                  ? oItems.map((oItem) => {
                      const subPro = oItem.sub_pro ;
                      console.log(subPro);
                      if(!oItem._id && !isAnyNewItemAdded){
                        isAnyNewItemAdded = true;
                      }
                      const subProAdOnNames =
                        subPro.addons
                          ? subPro.addons.map((subPro) => subPro.name)
                          : [];
                      const subProInstruction =
                        subPro && subPro.cookInstructions
                          ? subPro.cookInstructions
                          : [];
                      const subProVarieties =
                        subPro && subPro.variety &&  Object.keys(subPro.variety).length
                          ? Object.keys(subPro.variety)[0]
                          : "";
                      const addonTotalPrice =
                        subPro && subPro.addons
                          ? subPro.addons.reduce((acc, val) => {
                              if (val.price) {
                                return acc + parseFloat(val.price);
                              }
                              return acc;
                            }, 0)
                          : 0;
                      return (
                        <tr
                          style={{
                            borderBottom: "2px solid #FFBC00",
                            fontSize: "15px",
                            backgroundColor:!oItem._id?'#fff':'#b2b2b2'
                          }}
                        >
                          <td>
                            <b>{oItem.name} </b>
                            <br />
                              <Chip
                                label={subProVarieties.toUpperCase()}
                                className="chip-txt-var"
                              />
                            
                           
                              <Chip
                                label={subProAdOnNames.join(", ").toUpperCase()}
                                className="chip-txt"
                                
                              />
                           
                           
                              <Chip
                                label={subProInstruction.join(', ').toUpperCase()}
                                className="chip-txt-inst"
                              />
                           
                           
                          </td>
                          <td></td>
                          <td><b style={{fontSize:"1.2em"}}>{oItem.quantity}</b>
                            <br/><CurrencySymbol/>
                            {(Number(oItem.price) || 0) +
                              (subPro && subPro.addons
                                ? subPro.addons.reduce(
                                    (acc, val) =>
                                      acc + (Number(val.price) || 0),
                                    0
                                  )
                                : 0)}
                              
                          </td>
                           <td>
                           
                           {!oItem._id && <EditIcon
                              className="cart_edit"
                              onClick={() => (props.productClickHandler(oItem,true),props.setIsCartOpen(false))}
                            />}

                            {oItem._id && oItem.status.toUpperCase()=="PREPARING" && <img src='images/chef.gif'  style={{ width: "40px", height: "40px",backgroundColor:"#FFF",borderRadius:'40px',marginTop:"10px" }} />}

                            {oItem._id && oItem.status.toUpperCase()=="DELIVERED" && <img src='images/tick-green.png'  style={{ width: "35px", height: "35px",backgroundColor:"#FFF",borderRadius:'40px',marginTop:"10px",padding:"5px" }} />}

                            </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
        
        </div>
        <Box id="footorder" className={[styles.row, styles.around]}>
            <Button
              onClick={()=>props.setIsCartOpen(false)}
              size="large"
              variant="outlined"
              style={{
                backgroundColor: "#FFF !important",
                color: "#000 !important",
                padding: "6px 22px!important",
                border: '2px solid #000',
                borderRadius: "32px"
              }}
            >
              { t({ id: "cancel" })}
            </Button>

            {isAnyNewItemAdded ? <Button
              disabled={!oItems.length}
                  onClick={props.previewOrderHandler}
              variant="contained"
              size="large"
              className={styles.rightlargeButton}
              id="btcart_next"
            >
             {t({ id: "Next" })}
            </Button>:
            <Button
              onClick={()=>props.setIsCartOpen(false)}
              variant="contained"
              size="large"
              className={styles.rightlargeButton}
              id="btcart_next"
            >
             {t({ id: "add_more" })}
            </Button>}
          </Box>
         
          </Box>
  );
}
