import React, { useContext, useState ,useEffect} from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Fade,

  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Store } from '../Store';
import { setTableNumber } from '../actions';
import axios from 'axios';
import config,{getParameterByName,merchantCode} from '../Configs'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import './scrn.css';


export default function HomeScreen(props) {
  const { state, dispatch } = useContext(Store);

  const [tableData,setTableData]=useState([]);
  const styles = useStyles();
  const[tabNum,setTabNum]=useState();
    let {setting} = state.userSetting;
    console.log(setting);
    let {userInfo}= state.userData;
    const baseURL = config.baseURL;
    if(setting){
      let body = document.getElementsByTagName("body");
    body[0].style.backgroundColor = setting.color_tirnary;
     setTimeout(() => {
      let textcolor = document.getElementById("title1");
      let title = document.getElementById("title");
      let name = document.getElementById("name");
      textcolor.style.color = setting.color_secondary;
      title.style.color = setting.color_primary;
      name.style.color = setting.color_primary;
    }, 10);

   }

  //  https://api.menulive.in/api/tables?merchantCode=8D1ZV80O5E
   useEffect(()=>{
    const tablenum = document.querySelector("#choose_cards input").value? document.getElementById("seltab").value : getParameterByName("tableNumber");

if (tablenum){
  
  props.history.push('/order?' + window.location.href.split('?')[1]);
return;
}
    if(!tableData.length){
      axios.get(`${baseURL}/api/tables?merchantCode=${merchantCode}`).then((res)=>{
        setTableData(res.data);
      })
    }
   })



   let wthrurl = window.location.href.split("?")[0];
   let merchant = getParameterByName("merchantCode");
   let isScan = getParameterByName("isScan");

  console.log(document.getElementById(""))
  const handleNumber = (orderType) => { 
    console.log( document.querySelector("#choose_cards input").value)
    if(document.querySelector("#choose_cards input").value){
      console.log( document.querySelector("#choose_cards input").value)
      const tabData = tableData?tableData.filter(tabId => tabId.number ===  document.querySelector("#choose_cards input").value):""
      localStorage.setItem("tableId",tabData[0].id)
      console.log(tabData[0].id)
      // setTableNumber(dispatch,parseInt(tabNum));
     console.log(window.location.href + tabNum)

     window.location.href =
     wthrurl +
     "?merchantCode=" +
     merchant +
     "&isScan=" +
     isScan +
     "&tableNumber=" +
     document.querySelector("#choose_cards input").value;



    }
    else{
      document.getElementById("error").innerHTML="Please enter table Numbering"
    }
   
  };

  
  return (

    <>
      <Fade in={true}>
        <Box style={{ backgroundColor: state.selectedBgColor, height:"100vh" }}  >
       
          <Box  id="port-main" >
              <Box className={styles.center}>
                    <center>
                              <div className='logo'>
                             {userInfo&&userInfo.logoImg ? <img src={userInfo.logoImg} id='logo-img' style={{height:"auto",width:"300px",maxHeight:"200px"}}/> 
                               : ""}
                              </div>
                        </center>
                    </Box>
                    <div>
        
            <Box className={styles.center} id='choose_cards'>
            <span>
                <span id='error'></span><br/>
                <Select placeholder=' Select Table Number ' className='home'  id="seltab" inputProps={{ 'aria-label': 'Without label' }}  displayEmpty>
                <MenuItem disabled style={{backgroundColor:"#fff !important"}} >
            Select Table Number
          </MenuItem>

                {
                  tableData.length?tableData.map((tab)=>{
                    return <MenuItem disabled={!tab.isAvailable} value={tab.number} style={{backgroundColor:"#fff"}}>{tab.number}</MenuItem>
                  })
                  :""      
                }
                </Select>
              </span>
              
            </Box>
            {/* <select id="home" placeholder='Server name (optional)'>
              <option >None</option>
              
              {
                  tableData.length?tableData.map((tab)=>{
                    return  <option disabled={!tab.isAvailable} value={tab.number} style={{backgroundColor:"#fff"}}>{tab.number}</option>
                  })
                  :""
                }
              
   
  
  </select> */}
          
            <Box className={styles.cards} id='choose_cards' style={{marginTop:"25px"}}>
            <button className="home_btn" onClick={handleNumber}  id='back_btn' 
            style={{ width: "275px", textAlign: "center",height:"55px",borderRadius:"50px",background:"#000",border:"none",fontSize:"23px" }}
            >
            Next
          </button>
          
            </Box>
            </div>
          </Box>
          
       </Box>

      </Fade>
    </>

  );
}

{/* <button onClick={() => {
  props.history.push(`/?` + window.location.href.split('?')[1]);
}} className='btn btn-dark ms-4 mb-4' style={{width:"80px",textAlign:"center"}}>
  BACK
</button> */}