import React, { createContext, useReducer } from "react";
import useReducerWithThunk from "use-reducer-thunk";
import {
  ORDER_ADD_ITEM,
  ORDER_REMOVE_ITEM,
  ORDER_CLEAR,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  TABLE_NUMBER,
  ORDER_SET_PAYMENT_TYPE,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_REQUEST,
  SCREEN_SET_WIDTH,
  ORDER_QUEUE_LIST_REQUEST,
  ORDER_QUEUE_LIST_SUCCESS,
  ORDER_QUEUE_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  GENERATE_QRCODE_REQUEST,
  GENERATE_QRCODE_SUCCESS,
  GENERATE_QRCODE_FAIL,
  FETCH_QRCODE_REQUEST,
  FETCH_QRCODE_SUCCESS,
  FETCH_QRCODE_FAIL,
  FETCH_USERDATA_REQUEST,
  FETCH_USERDATA_SUCCESS,
  FETCH_USERDATA_FAIL,
  FETCH_USERSETTING_REQUEST,
  FETCH_USERSETTING_SUCCESS,
  FETCH_USERSETTING_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  FETCH_ORDER,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAIL,
  TABLE_REQUEST,
  TABLE_SUCCESS
} from "./constants";

export const Store = createContext();

const getParameterByName = (e, t = window.location.href) => {
  e = e.replace(/[\[\]]/g, "\\$&");
  var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
  return n ? (n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "") : null;
};
const userCurrency = getParameterByName("currency");
// const userId=getParameterByName('userid');
const logo = getParameterByName("logo");

let itemCount = localStorage.getItem("itemsCount");

const getCurrencyByName = (currency) => {
  return {
    usd: "$",
    inr: "₹",
  }[currency];
};
const initialState = {
  widthScreen: true,
  orderList: { loading: true },
  queueList: { loading: true },
  categoryList: { loading: true },
  productList: { loading: true, products: [] },
  userData: { loading: true, userInfo: [] },
  userSetting: { loading: true },
  orderId: "",
  order: {
    orderItems: [],
    number: 1,
    tableId: '',
    paymentType: "At Counter",
    orderType: "Eat In",
    taxPrice: 0,
    totalPrice: 0,
    itemsCount: 0,
    userId: "",
    orderStatus: "NEW",
    orderSource: "Table Order",
  },
  table:{ loading: true, details: {} },
  newQr: {
    loading: true,
    data: null,
    error: null,
  },
  fetchOrder: {
    loading: true,
    data: null,
    error: null,
  },
  selectedCurrency: "",
  selectedLogo: getParameterByName("logo"),
  orderCreate: { loading: true },
  taxPercent: "",
  taxPercent:0,
  takeAwayTax:0,
  dineinTax:0,
  themeColor:'#ffbc01',
  themeTxtColor:'#000',
  customizeInWizard:false
};

function reducer(state, action) {
  console.log(action.payload);
  switch (action.type) {
    case SCREEN_SET_WIDTH:
      return {
        ...state,
        widthScreen: true,
      };

    case TABLE_REQUEST:
        return { ...state, table: { loading: true, details:{} } };
        
      case TABLE_SUCCESS:
      return {
        ...state,
        table: { loading: false, details: action.payload }
      };

    case TABLE_NUMBER:
      return {
        ...state,
        order: { ...state.order, tableNumber: action.payload },
      };
    case ORDER_SET_PAYMENT_TYPE:
      return {
        ...state,
        order: { ...state.order, paymentType: action.payload },
      };

    case CATEGORY_LIST_REQUEST:
      return { ...state, categoryList: { loading: true } };
    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: { loading: false, categories: action.payload },
      };
    case CATEGORY_LIST_FAIL:
      return {
        ...state,
        categoryList: { loading: false, error: action.payload },
      };

    case FETCH_USERDATA_REQUEST:
      return { ...state, userData: { loading: true } };
    case FETCH_USERDATA_SUCCESS:
      return {
        ...state,
        order: { ...state.order, userId: action.payload.merchantCode },
        userData: { loading: false, userInfo: action.payload },
        selectedCurrency:action.payload.currency.toLowerCase(),
        taxPercent: action.payload.taxPerc,
            takeAwayTax:action.payload.takeAwayTax,
            dineinTax:action.payload.dineinTax,
            themeColor:action.payload.themeColor,
            themeTxtColor:action.payload.themeTxtColor,
            customizeInWizard:action.payload.customizeInWizard
      };
    case FETCH_USERDATA_FAIL:
      return {
        ...state,
        userData: { loading: false, error: action.payload },
      };

    case FETCH_USERSETTING_REQUEST:
      return { ...state, userSetting: { loading: true } };
    case FETCH_USERSETTING_SUCCESS:
      return {
        ...state,
        userSetting: { loading: false, setting: action.payload },
         taxPercent:action.payload.taxPerc,
            takeAwayTax:action.payload.takeAwayTax,
            dineinTax:action.payload.dineinTax,
            themeColor:action.payload.themeColor,
            themeTxtColor:action.payload.themeTxtColor,
            customizeInWizard:action.payload.customizeInWizard,
                selectedCurrency:action.payload.currency.toLowerCase()

        // taxPercent:action.payload.taxPercent
      };
    case FETCH_USERSETTING_FAIL:
      return {
        ...state,
        userSetting: { loading: false, error: action.payload },
      };

    case PRODUCT_LIST_REQUEST:
      return { ...state, productList: { loading: true } };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: { loading: false, products: action.payload },
      };
    case PRODUCT_LIST_FAIL:
      return {
        ...state,
        productList: { loading: false, error: action.payload },
      };
    case ORDER_QUEUE_LIST_REQUEST:
      return { ...state, queueList: { loading: true } };
    case ORDER_QUEUE_LIST_SUCCESS:
      return {
        ...state,
        queueList: { loading: false, queue: action.payload },
      };
    case ORDER_QUEUE_LIST_FAIL:
      return {
        ...state,
        queueList: { loading: false, error: action.payload },
      };
    case ORDER_LIST_REQUEST:
      return { ...state, orderList: { loading: true } };
    case ORDER_LIST_SUCCESS:
      return {
        ...state,
        orderList: { loading: false, orders: action.payload },
      };
    case ORDER_LIST_FAIL:
      return {
        ...state,
        orderList: { loading: false, error: action.payload },
      };
    case ORDER_CREATE_REQUEST:
      return { ...state, orderCreate: { loading: true } };
    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        orderCreate: { loading: false, newOrder: action.payload },
        // order: {
        //   orderItems:action.payload.orderItems,
        //   tableNumber: parseInt(tabNumber),
        //   paymentType: 'At Counter',
        //   orderType:"Eat In",
        //   taxPrice: action.payload.taxPrice,
        //   totalPrice: action.payload.totalPrice,
        //   itemsCount: parseInt(itemCount),
        //   userId:action.payload.userId,
        //   orderStatus:"NEW",
        //   orderSource:"Table Order",
        // },
        // orderId:action.payload._id,
      };
    case ORDER_CREATE_FAIL:
      return {
        ...state,
        orderCreate: { loading: false, error: action.payload },
      };
    case ORDER_ADD_ITEM: {
      const item = action.payload;
      console.log(state.order)
      console.log(item)
      const existItem =state.order && state.order.orderItems ?  state.order.orderItems.find(
        (x) => x.id === item.id
      ):""
      const orderItems = existItem
        ? state.order.orderItems.map((x) =>
            x.id === existItem.id ? item : x
          )
        : [...state.order.orderItems, item];

      let addonTotalPrice = 0;

      orderItems.forEach((item) => {
        console.log(item.sub_pro)
        const subProArray = typeof item.sub_pro === "string" ? JSON.parse(item.sub_pro) : item.sub_pro;

        if (subProArray && subProArray.addons) {
          subProArray.addons.forEach((ad) => {
            if (ad.price) {
              addonTotalPrice += parseFloat(ad.price);
            }
          });
        } else {
          console.error("subProArray or subProArray.addons is not defined.");
        }
      });

      console.log(addonTotalPrice);

      const itemsCount = orderItems.reduce((a, c) => a + c.quantity, 0);

      console.log(itemsCount, orderItems);
      const itemsPrice = orderItems.reduce(
        (a, c) => a + c.quantity * c.price,
        0
      );
      console.log(itemsPrice);
      const itemquantity = orderItems[0].quantity * addonTotalPrice;
      console.log(itemquantity);
      console.log(state.taxPercent);
      const taxPrice =
        Math.round(
          state.taxPercent
            ? (state.taxPercent / 100) * itemsPrice * 100
            : (0 / 100) * itemsPrice * 100
        ) / 100;
      // const taxPrice = 5;
      const totalPrice =
        Math.round((itemsPrice + itemquantity + taxPrice) * 100) / 100;
      console.log(totalPrice);
      return {
        ...state,
        order: {
          ...state.order,
          orderItems,
          taxPrice,
          totalPrice,
          itemsCount,
        },
      };
    }
    case ORDER_REMOVE_ITEM:
      const orderItems = state.order.orderItems.filter(
        (x) => x.name !== action.payload.name
      );
      let addonTotalPrice = 0;

      orderItems.forEach((item) => {
        let addons = [];
      
        if (typeof item.sub_pro === "string") {
          try {
            addons = JSON.parse(item.sub_pro).addons;
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else if (Array.isArray(item.sub_pro.addons)) {
          addons = item.sub_pro.addons;
        }
      
        addonTotalPrice += addons.reduce((total, ad) => total + ad.price, 0);
      });
      console.log(addonTotalPrice);
      const itemsCount = orderItems.reduce((a, c) => a + c.quantity, 0);
      const itemsPrice = orderItems.reduce(
        (a, c) => a + c.quantity * c.price,
        0
      );
      console.log(itemsPrice);
      const itemquantity =
        orderItems && orderItems[0]
          ? orderItems[0].quantity * addonTotalPrice
          : 0;
      console.log(itemquantity);

      // const taxPrice = Math.round(state.taxPercent/100 * itemsPrice * 100) / 100;
      const taxPrice = 5;
      const totalPrice =
        Math.round((itemsPrice + itemquantity + taxPrice) * 100) / 100;
      console.log(totalPrice);
      return {
        ...state,
        order: {
          ...state.order,
          orderItems,
          taxPrice,
          totalPrice,
          itemsCount,
        },
      };

    case ORDER_CLEAR:
      return {
        ...state,
        order: {
          orderItems: [],
          taxPrice: 0,
          totalPrice: 0,
          itemsCount: 0,
        },
      };

    case GENERATE_QRCODE_REQUEST:
      return { ...state, newQr: { loading: true } };
    case GENERATE_QRCODE_SUCCESS:
      return {
        ...state,
        newQr: { loading: false, data: action.payload },
      };
    case GENERATE_QRCODE_FAIL:
      return {
        ...state,
        newQr: { loading: false, data: action.payload },
      };

    case FETCH_ORDER:
      return { ...state, fetchOrder: { loading: true } };
    case FETCH_ORDER_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        fetchOrder: { loading: false, data: action.payload },
        order: {
          orderItems: action.payload && action.payload.orderItems? action.payload.orderItems : [],
          tableNumber: parseInt(action.payload.tabNumber),
          tableId: action.payload ?action.payload.tableId:"",
          paymentType: "At Counter",
          orderType: "Eat In",
          taxPrice: action.payload ? action.payload.taxPrice : "",
          totalPrice: action.payload ? action.payload.totalPrice : "",
          number: parseInt(action.payload.tabNumber),
          itemsCount: action.payload ? parseInt(
            action.payload.orderItems ? action.payload.orderItems.length : 0
          ):"",
          userId: action.payload ? action.payload.userId : "",
          orderStatus: "NEW",
          orderSource: "Table Order",
          orderId:action.payload ? action.payload.id : "",

        },
        orderId:action.payload ?  action.payload._id : "",
      };
    case FETCH_ORDER_FAIL:
      console.log("error");
      return {
        ...state,
        fetchOrder: { loading: false, data: action.payload },
      };

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  //const [state, dispatch] = useReducerWithThunk(reducer, initialState, 'example');

  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
