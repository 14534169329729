import React from 'react'
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Button from '@mui/material/Button';




function Promotiion(props) {

  return (
    <div style={{ overflow: "hidden !important", height: "auto" }}>
      <div className="slide-container" style={{ overflow: "hidden", height: "90% !important" }}>
        <Fade>


          <div className="each-fade" >
            <img src={"./images/promotion.jpeg"} alt="Promotion 1" style={{ width: "100%", objectFit: "contain" }} />
          </div>
          <div className="each-fade"  >
            <img src={"./images/promotion_1.jpeg"} alt="Promotion 2" style={{ width: "100%", objectFit: "contain" }} />
          </div>





        </Fade>
      </div>
      <div style={{ display: "flex", justifyContent: "end", width: "100%", padding: "10px", height: "10%" }}>
        <Button variant="contained" color="success" onClick={() => {
          props.history.push(`/addmore?` + window.location.href.split('?')[1]);
        }}>
          Back To Order
        </Button>
      </div>
    </div>
  )
}

export default Promotiion