import React, { useContext, useEffect } from "react";
import { useStyles } from "../styles";
import { Store } from "../Store";
import axios from "axios";

import {
  Box,
  Button,

} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import Chip from "@mui/material/Chip";
import CallIcon from "@mui/icons-material/Call";

import {
  setTableNumber,

  clearOrder,
  
  // fetchOrder,
} from "../actions";
import { getParameterByName } from "../Configs";
import config, {  merchantCode } from "../Configs";
import {FETCH_ORDER_SUCCESS} from "../constants"
function AddMoreScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const baseURL = config.baseURL;
  const { order, selectedCurrency } = state;
  const { loading, error, newOrder } = state.orderCreate;
  const {
    orderItems,
    itemsCount,
    totalPrice,
    taxPrice,
    orderType,
    tableNumber,
  } = order;

  const tableNo = getParameterByName("tableNumber");

  return (
    <Box
      className={styles.root}
      style={{
        backgroundColor: state.selectedBgColor,
        height: "100%",
        overflow: "hidden !important",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "60px",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "190px",
          }}
        >
          <span
            onClick={() => {
              // clearOrder(dispatch);
              props.history.push(
                `/review?` + window.location.href.split("?")[1]
              );
            }}
            className="back_arrow"
          >
            <img
              src="images/back.png"
              alt="BigCo Inc. logo"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
          <h2>Table # {tableNo}</h2>
        </span>
        <Button
          variant="contained"
          color="success"
          id="waiterBtn"
          startIcon={<CallIcon />}
        >
          Call Service
        </Button>
        <div></div>
      </div>
      <Box className="admor">
        <Box className="admore_items">
          <Box className="admore_sub">
            <h3
              align="center"
              style={{ fontSize: "20px", fontFamily: "arial" }}
            >
              Order Status
            </h3>
            <table
              cellPadding="10px"
              cellSpacing="10px"
              align="center"
              id="tab"
            >
              <tr
                style={{
                  fontSize: "22px",
                  fontFamily: "arial",
                  textAlign: "left",
                }}
                id="adhaead"
              >
                <th>Item</th>
              </tr>
              {orderItems ? orderItems.map((orderItem, index) => (
                    <tr
                      style={{
                        fontSize: "22px",
                        fontFamily: "arial",
                        textAlign: "left",
                      }}
                      id="adbody"
                    >
                      <td>{orderItem.name}</td>
                      <td>
                      <Chip
                               label={orderItem.status ? orderItem.status.toUpperCase() : "PREPARING"}
                                color={   orderItem.status === "ready"
                                  ? "primary"
                                  : orderItem.status === "Preparing"
                                  ? "warning"
                                  : orderItem.status === "delivered"
                                  ? "success"
                                  : "info"
                              }
                                style={{fontWeight:"bold"}}
                              />
                       
                      </td>
                    </tr>
                  ))
                  :""
              }
            </table>
          </Box>

          <Box className="admore_sub1">
            <div style={{ margin: "40px 0px" }} id="adtxt">
              <h3>Want to Order More?</h3>
              <button
                onClick={() => {
                  setTableNumber(dispatch, tableNumber);
                  // fetch();
                  props.history.push(
                    `/order?` + window.location.href.split("?")[1]
                  );
                }}
                className="admore_btns"
              >
                ADD MORE
              </button>
            </div>

            <div style={{ margin: "40px 0px" }}>
              <h3>Done with your meal</h3>
              <button
                className="admorebtn"
                //   onClick={procedToCheckoutHandler}
                // disabled={mapingOrder&&mapingOrder.orderItems.length === 0}
                onClick={() => {
                  // fetch();
                  props.history.push(
                    `/complete?` + window.location.href.split("?")[1]
                  );
                }}
              >
                Payment
              </button>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AddMoreScreen;
